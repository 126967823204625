import { PaletteColorOptions, SimplePaletteColorOptions, createTheme } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    black: SimplePaletteColorOptions;
    lightBlue: PaletteColorOptions;
    background1: SimplePaletteColorOptions;
    outline: SimplePaletteColorOptions;
  }
  interface PaletteOptions {
    black: SimplePaletteColorOptions;
    lightBlue: PaletteColorOptions;
    background1: SimplePaletteColorOptions;
    outline: SimplePaletteColorOptions;
  }

  interface TypographyVariants {
    mediumText: React.CSSProperties;
    subtitle: React.CSSProperties;
    title: React.CSSProperties;
    title2: React.CSSProperties;
    mobileTitle: React.CSSProperties;
    mobileTitle2: React.CSSProperties;
    subtitle3: React.CSSProperties;
    smallTextBold: React.CSSProperties;
    tooltip: React.CSSProperties;
    body3: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    mediumText?: React.CSSProperties;
    subtitle?: React.CSSProperties;
    title?: React.CSSProperties;
    title2?: React.CSSProperties;
    mobileTitle?: React.CSSProperties;
    mobileTitle2?: React.CSSProperties;
    subtitle3: React.CSSProperties;
    smallTextBold?: React.CSSProperties;
    tooltip?: React.CSSProperties;
    body3: React.CSSProperties;
  }

  interface SvgIconPropsColorOverrides {
    outline: SimplePaletteColorOptions;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    black: true;
  }
}
declare module '@mui/material/Checkbox' {
  interface CheckboxPropsColorOverrides {
    lightBlue: true;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    smallTextBold: true;
    tooltip: true;
    subtitle: true;
    title2: true;
    mobileTitle: true;
    mobileTitle2: true;
    h3: false;
    mediumText: true;
    title: true;
    body3: true;
    subtitle3: true;
  }
}

// Update the Buttons's variant prop options
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    mainButton: true;
    lightPrimary: true;
    customButton: true;
  }
}
// Update the TextField's variant prop options
declare module '@mui/material/TextField' {
  interface TextFieldPropsVariantOverrides {
    main: true;
  }
}
// Update the Buttons's variant prop options
declare module '@mui/system/createTheme/createBreakpoints' {
  interface BreakpointOverrides {
    mobile: true;
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

let theme = createTheme({
  // Theme customization goes here as usual, including tonalOffset and/or
  // contrastThreshold as the augmentColor() function relies on these
  breakpoints: {
    values: {
      mobile: 0,
      xs: 0,
      tablet: 600,
      sm: 600,
      md: 900,
      lg: 1200,
      laptop: 1281,
      xl: 1536,
      desktop: 1536
    }
  },
  palette: {
    primary: {
      main: '#753BBD',
      light: '#E6DBF9'
    },
    secondary: {
      main: '#EE7623'
    },
    success: {
      main: '#12B76A'
    },
    background1: {
      main: '#F7F6F5',
      dark: '#EEEDEF'
    },
    black: {
      main: '#0E0E0E',
      light: '#505050'
    },
    lightBlue: { main: '#30B1E8' },
    outline: {
      main: '#79747E'
    },
    grey: {
      100: '#FAF9FB',
      200: '#E4E4E4',
      500: '#79747E',
      600: '#505050'
    }
  }
});

theme = createTheme(theme, {
  // Custom colors created with augmentColor go here
  typography: {
    fontFamily: 'Manrope',
    allVariants: {
      fontFamily: 'Manrope'
    },
    body1: {
      fontSize: '14px',
      fontWeight: 500
    },
    body2: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: '-0.08px'
    },
    body3: {
      fontSize: '12px'
    },
    subtitle1: {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '24px',
      color: theme.palette.black.main
    },
    smallTextBold: {
      fontSize: '14px',
      fontWeight: 700
    },
    mediumText: {
      fontSize: '16px',
      fontWeight: 500
    },
    title: {
      fontSize: '28px',
      fontWeight: 700,
      lineHeight: '36px',
      color: theme.palette.black.main
    },
    title2: {
      fontSize: '48px',
      fontWeight: 700,
      textAlign: 'center'
    },
    subtitle2: {
      fontSize: '18px',
      fontWeight: 700,
      textAlign: 'center'
    },
    subtitle3: {
      fontSize: '20px',
      fontWeight: 700
    },
    tooltip: {
      fontSize: '10px',
      fontWeight: 500
    },
    mobileTitle: {
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: '32px',
      color: theme.palette.black.main
    },
    mobileTitle2: {
      fontSize: '28px',
      fontWeight: 700,
      color: '#282422',
      textAlign: 'center'
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Manrope',
          textTransform: 'none',
          height: '40px',
          borderRadius: 800,
          padding: '8px 24px',
          minWidth: 'auto'
        }
      },
      variants: [
        {
          props: { variant: 'mainButton' },
          style: {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
              color: 'white'
            },
            '&:disabled': {
              backgroundColor: '#F7F6F5'
            }
          }
        },
        {
          props: { variant: 'lightPrimary' },
          style: {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.main,
            '&:hover': {
              backgroundColor: theme.palette.primary.light
            }
          }
        },
        {
          props: { variant: 'customButton' },
          style: {
            backgroundColor: '#793DB5',
            color: 'white',
            height: '42px',
            width: 'auto',
            fontSize: '14px',
            '&:hover': {
              backgroundColor: '#793DB5',
              color: 'white',
              boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)'
            },
            '&:disabled': {
              backgroundColor: '#F7F6F5',
              color: '#D9D5CF'
            }
          }
        },
        {
          props: { variant: 'outlined' },
          style: {
            borderColor: theme.palette.outline.main,
            color: theme.palette.primary.main,
            '&:hover': {
              backgroundColor: 'transparent',
              borderColor: theme.palette.outline.main,
              color: theme.palette.primary.main
            }
          }
        }
      ]
    },
    MuiTypography: {
      defaultProps: {
        fontFamily: 'Manrope',
        variantMapping: {
          // Map the new variant to render a <h1> by default
          smallTextBold: 'h1'
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& label': {
            top: '4px'
          },
          '& label.Mui-focused': {
            color: '#341358'
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: 'rgba(0, 0, 0, 0.23)',
            borderWidth: '1px'
          },
          '& .MuiOutlinedInput-root': {
            height: '50px',
            margin: '0 ',
            '&.MuiInputBase-multiline': {
              height: 'auto'
            },
            '& fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
              borderWidth: '1px'
            },
            '&:hover fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
              borderWidth: '1px'
            },
            '&.Mui-focused fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
              borderWidth: '1px'
            }
          }
        }
      }
    },
    MuiMobileStepper: {
      styleOverrides: {
        dots: {
          display: 'none'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderWidth: '1px',
          borderStyle: 'solid',
          height: '25px',
          fontSize: '12px'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: 'Manrope'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: 'white',
          backgroundColor: 'black'
        }
      }
    },
    MuiTextField: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            '& label.Mui-focused': {
              color: 'grey'
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: 'grey'
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'grey.500'
              },
              '&:hover fieldset': {
                borderColor: 'grey'
              },
              '&.Mui-focused fieldset': {
                borderColor: 'grey'
              }
            }
          }
        }
      ]
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& label': {
            top: '4px !important'
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Manrope'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'Manrope'
        }
      }
    }
  }
});

export default theme;

export const INPUT_STYLE = {
  '.MuiOutlinedInput-notchedOutline, .MuiInputLabel-shrink': { fontSize: '18px !important' },
  '.MuiInputLabel-shrink': {
    top: 'initial',
    color: 'black !important'
  },
  '.MuiInputLabel-root': {
    paddingTop: '5px'
  }
};
